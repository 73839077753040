import React from 'react'

import Container from './container'
import * as styles from './footer.module.css'

const Footer = () => (
  <Container as="footer">
    <div className={styles.container}>
      お問い合わせはこちら<br />
      <div class="contact-phone"><span><i class="fa fa-phone"></i>Phone: </span>03-3851-8707</div>
      <div class="contact-email"><span><i class="fa fa-envelope"></i>Email: </span><a href="mailto:life_form_stm@yahoo.co.jp">life_form_stm@yahoo.co.jp</a></div>
    </div>
  </Container >
)

export default Footer
