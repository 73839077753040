import React from 'react'
import { Link } from 'gatsby'

import * as styles from './navigation.module.css'

const Navigation = () => (
  <nav role="navigation" className={styles.container} aria-label="Main">
    <Link to="/" className={styles.logoLink}>
      <span className={styles.navigationItem}>タマ美容化学</span>
    </Link>
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        <Link to="/" activeClassName="active">
          ホーム
        </Link>
      </li>
      <li className={styles.navigationItem}>
        <Link  activeClassName="active">
          Blog
        </Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/item/" activeClassName="active">
          製品
        </Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="https://subscribepage.io/tamabiyoukagaku-oem" activeClassName="active">
          OEM
        </Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/access/" activeClassName="active">
          アクセス
        </Link>
      </li>
      <li className={styles.navigationItem}>
          <a href="https://tamabiyoukagaku.stores.jp/">ネットショップ</a>
      </li>
    </ul>
  </nav>
)

export default Navigation
